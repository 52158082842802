<template>
  <li class="d-flex align-items-center p-0">
    <div
      @click="$emit('enter')"
      :class="{ 'is-busy': isDeleting }"
      class="d-flex align-items-center flex-grow-1 py-2 me-3"
      role="button"
    >
      <fa-icon :icon="['fas', 'folder']" class="me-2"></fa-icon>
      <div class="flex-grow-1">
        {{ group.name }}
        <span v-if="metricsCount > 0" class="smaller fst-italicx text-muted">
          ({{ metricsCount }} item{{ metricsCount > 1 ? 's' : '' }})
        </span>
      </div>
    </div>

    <BootstrapDropdown :class="{ 'is-busy': isDeleting }">
      <li
        @click="$emit('update')"
        class="dropdown-item small"
        role="button"
      >
        <fa-icon :icon="['fas', 'edit']" class="me-1"></fa-icon>
        Edit
      </li>
      <li><hr class="dropdown-divider"></li>
      <li
        @click="showConfirmModal = true"
        class="dropdown-item small text-danger"
        role="button"
      >
        <fa-icon :icon="['fas', 'trash-alt']" class="me-1"></fa-icon>
        Remove
      </li>
    </BootstrapDropdown>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteGroup"
          @close="showConfirmModal = false"
          v-model="showConfirmModal"
          v-if="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MetricListGroup',
  components: {
    BootstrapDropdown: () => import('@/components/bootstrap/BootstrapDropdown'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('metric', ['metricItemsOnGroup']),
    metricsCount() {
      const { group, metricItemsOnGroup } = this;
      return metricItemsOnGroup(group.metricGroupId).length;
    },
  },
  methods: {
    async deleteGroup() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const itemId = this.group.metricGroupId;
        await this.$store.dispatch('metric/deleteGroup', itemId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
  },
  data() {
    return {
      isDeleting: false,
      showConfirmModal: false,
    };
  },
};
</script>
